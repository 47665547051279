import React from 'react';
import styled from 'styled-components';
import { Vector } from '@fragments';
import { toVW } from '@helpers/methods';
import { getTypography, spaceDt, screenMin } from '@helpers/styles';

const ListDetails = styled.div`
  padding: ${spaceDt(2.5)};
  margin-bottom: ${spaceDt(5)};
  ${getTypography('body-1')}

  ${screenMin('lg')} {
    padding-left: 0;
    width: 100%;
    margin-bottom: ${spaceDt(2.5)};

    &:not(:last-child) {
      margin-right: ${spaceDt(7)};
    }

    &:first-of-type {
      max-width: ${toVW(280, 'desktop')};
    }
  }
`;
const ListDescription = styled.div`
  margin-top: ${spaceDt(1)};

  ${screenMin('lg')} {
    line-height: ${toVW(35, 'desktop')};
  }
`;

const ListVector = styled((props) => <Vector {...props} />)`
  width: ${toVW(33, 'mobile')};
  height: ${toVW(10, 'mobile')};

  ${screenMin('lg')} {
    width: ${toVW(81, 'desktop')};
    height: ${toVW(24, 'desktop')};
  }
`;

export { ListDetails, ListDescription, ListVector };
