import React from 'react';
import { SEO, TextEffect } from '@fragments';
import ContactForm from '@features/ContactForm';
import { ContactFormTitle, ContactFormSubtitle } from './Manifesto.styled';
import SectionHeader from './sections/SectionHeader';
import SectionOptimize from './sections/SectionOptimize';
import SectionInequalities from './sections/SectionInequalities';
import SectionTransparency from './sections/SectionTransparency';
import SectionQuote from './sections/SectionQuote';
import SectionSign from './sections/SectionSign';

const headlineComponent = (
  <>
    <ContactFormTitle>
      Let&apos;s create the new <br /> economy together.
    </ContactFormTitle>

    <ContactFormSubtitle>
      Join our team <TextEffect effect="text-primary">or</TextEffect> tell us about your project
    </ContactFormSubtitle>
  </>
);

const Manifesto = () => (
  <>
    <SEO
      title="Manifesto | Ragnarson"
      description="We believe that every company can have a positive impact on our society and environment. We want to lead by example and here is how we are going to achieve it."
    />
    <SectionHeader />
    <SectionQuote />
    <SectionOptimize />
    <SectionInequalities />
    <SectionTransparency />
    <SectionSign />

    <ContactForm headline={headlineComponent} />
  </>
);

export default Manifesto;
