import React from 'react';
import styled from 'styled-components';
import { Container } from '@fragments';
import { RagnarStamp } from '@illustrations';
import { getTypography, spaceMb, spaceDt, screenMin } from '@helpers/styles';
import dataManifesto from '../Manifesto.data';

const { team } = dataManifesto.sectionTransparency;

const SectionContainer = styled(Container)`
  flex-direction: row;
  align-items: center;
  margin-bottom: ${spaceMb(10)};

  ${screenMin('lg')} {
    margin-bottom: ${spaceDt(20)};
  }
`;

const TeamSign = styled.p`
  ${getTypography('heading-3')}
  margin-left: auto;
  margin-right: ${spaceMb(2)};
  margin-bottom: 0;

  ${screenMin('lg')} {
    margin-right: ${spaceDt(6)};
  }
`;

const SectionSign = () => (
  <SectionContainer>
    <TeamSign>{team.sign}</TeamSign>

    <RagnarStamp />
  </SectionContainer>
);

export default SectionSign;
