import React from 'react';
import { useStateScreenMobile } from '@helpers/hooks';
import { SectionContainer, ContentWrapper, Content, LinkExternalArticle, Hashtag } from './SectionQuote.styled';
import { StyledVikingHelmet } from './SectionHeader';
import dataManifesto from '../Manifesto.data';

const { mainSection } = dataManifesto.sectionQuote;

const SectionQuote = () => {
  return (
    <SectionContainer>
      <ContentWrapper>
        <Content>
          <Hashtag name="icon-hashtag" position="top-left" />
          <span>{mainSection.content}</span>
          <Hashtag name="icon-hashtag" position="bottom-right" />
        </Content>

        <LinkExternalArticle to={mainSection.to}>{mainSection.name}</LinkExternalArticle>
      </ContentWrapper>

      {!useStateScreenMobile() && <StyledVikingHelmet />}
    </SectionContainer>
  );
};

export default SectionQuote;
