import React from 'react';
import styled from 'styled-components';
import { spaceMb, spaceDt, screenMin, getTypography, color } from '@helpers/styles';
import { toVW } from '@helpers/methods';
import { ContactFormHeader } from '@features/ContactForm/ContactForm.styled';
import { Container, ContentBlock, Vector } from '@fragments';

const Row = styled.div`
  display: flex;
  flex-direction: column;
  position: relative;
  margin-left: ${spaceDt(5)};

  ${screenMin('lg')} {
    margin-top: 0;
    flex-direction: row;
  }
`;

const Highlight = styled.em`
  font-style: normal;
  color: ${color.bg.secondary};

  ${screenMin('lg')} {
    white-space: pre;
  }
`;

const SectionVector = styled((props) => <Vector {...props} />)`
  width: ${toVW(40, 'mobile')};
  height: ${toVW(40, 'mobile')};
  position: absolute;
  top: 0;
  left: ${toVW(-20, 'mobile')};
  z-index: -1;

  ${screenMin('lg')} {
    width: ${toVW(129, 'desktop')};
    height: ${toVW(129, 'desktop')};
    position: absolute;
    top: ${spaceDt(2)};
    left: ${toVW(-65, 'desktop')};
  }
`;

const SectionTop = styled(ContentBlock)`
  margin-top: ${spaceDt(10)};

  .content-block__content {
    ${getTypography('heading-3')};
  }
`;

const SectionWrapper = styled(Container)`
  margin-bottom: ${spaceDt(10)};

  ${screenMin('lg')} {
    margin-bottom: ${spaceDt(10)};
  }
`;

const ContactFormTitle = styled(ContactFormHeader)`
  ${getTypography('heading-2')};
`;

const ContactFormSubtitle = styled.p`
  ${getTypography('heading-3')};
  color: ${color.text.secondary};
  margin-bottom: ${spaceMb(5)};
  padding-left: ${spaceMb(1.5)};

  ${screenMin('lg')} {
    margin-bottom: ${spaceDt(6)};
    padding-left: 0;
  }
`;

export { Row, SectionVector, SectionTop, SectionWrapper, Highlight, ContactFormTitle, ContactFormSubtitle };
