import React from 'react';
import styled from 'styled-components';
import { spaceMb, spaceDt, screenMax, screenMin } from '@helpers/styles';
import { toVW } from '@helpers/methods';
import { Container, PageHeader } from '@fragments';
import { useStateScreenMobile } from '@helpers/hooks';
import { VikingHelmet } from '@illustrations';
import dataManifesto from '../Manifesto.data';

const PageHeaderManifesto = styled(PageHeader)`
  margin-bottom: ${spaceMb(0)};
  margin-top: ${spaceMb(2)};

  .page-headline {
    max-width: initial;
  }

  ${screenMax('lg')} {
    br {
      display: none;
    }
  }
`;

export const StyledVikingHelmet = styled(VikingHelmet)`
  width: ${toVW(193, 'mobile')};
  height: ${toVW(228, 'mobile')};

  ${screenMax('lg')} {
    margin-top: ${spaceMb(6)};
    margin-left: auto;
    margin-right: auto;
  }

  ${screenMin('lg')} {
    width: ${toVW(379, 'desktop')};
    height: ${toVW(444, 'desktop')};
    margin-right: ${spaceDt(17)};
  }
`;

const {
  sectionHeader: { name, headline },
} = dataManifesto;

const SectionHeader = () => {
  return (
    <>
      {useStateScreenMobile() && (
        <Container>
          <StyledVikingHelmet />
        </Container>
      )}
      <PageHeaderManifesto name={name} headline={headline} content="" />
    </>
  );
};

export default SectionHeader;
