import React from 'react';
import styled from 'styled-components';
import { getTypography } from '@helpers/styles';
import { ContentBlock } from '@fragments';
import SectionList from './SectionList';
import { Row, SectionVector, SectionTop, SectionWrapper } from '../Manifesto.styled';
import dataManifesto from '../Manifesto.data';

const SectionMoreInfo = styled(ContentBlock)`
  .content-block__content {
    ${getTypography('body-1')}
  }
`;

const { mainSection, bottomSection, moreInfo } = dataManifesto.sectionInequalities;

const SectionInequalities = () => {
  return (
    <SectionWrapper>
      <Row>
        <SectionVector name={mainSection.icon} />
        <SectionTop content={mainSection.content} />
      </Row>
      <Row>
        <SectionList vectorName={bottomSection[0].icon} content={bottomSection[0].content} />
        <SectionList vectorName={bottomSection[1].icon} content={bottomSection[1].content} />
      </Row>
      <Row>
        <SectionMoreInfo content={moreInfo.content} />
      </Row>
    </SectionWrapper>
  );
};

export default SectionInequalities;
