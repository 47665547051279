import React from 'react';
import { Row, SectionVector, SectionTop, SectionWrapper } from '../Manifesto.styled';
import SectionList from './SectionList';
import dataManifesto from '../Manifesto.data';

const { mainSection, bottomSection } = dataManifesto.sectionTransparency;

const SectionTransparency = () => {
  return (
    <SectionWrapper>
      <Row>
        <SectionVector name={mainSection.icon} />
        <SectionTop content={mainSection.content} />
      </Row>
      <Row>
        <SectionList vectorName={bottomSection[0].icon} content={bottomSection[0].content} />
        <SectionList vectorName={bottomSection[1].icon} content={bottomSection[1].content} />
      </Row>
    </SectionWrapper>
  );
};

export default SectionTransparency;
