import React from 'react';
import styled, { css } from 'styled-components';
import { spaceDt, spaceMb, screenMin, screenMax, getTypography, color } from '@helpers/styles';
import { toVW } from '@helpers/methods';
import { Container, LinkExternal, Vector } from '@fragments';

const LinkExternalArticle = styled(LinkExternal)`
  ${getTypography('body-1')}
  text-align: center;
  color: ${color.text.secondary};
`;

const SectionContainer = styled(Container)`
  flex-direction: column-reverse;
  align-items: top;
  position: relative;
  margin-bottom: ${spaceMb(5)};
  padding-top: ${spaceMb(5)};
  margin-top: 0;

  ${screenMin('lg')} {
    margin-bottom: ${spaceMb(2)};
    margin-top: ${spaceDt(-20)};
    padding: 0;
    flex-direction: row;
    justify-content: space-between;
  }
`;

const Hashtag = styled((props) => <Vector {...props} />)`
  flex-shrink: 0;
  width: ${toVW(16, 'mobile')};
  height: ${toVW(20, 'mobile')};

  ${screenMin('lg')} {
    width: ${toVW(33, 'desktop')};
    height: ${toVW(42, 'desktop')};
  }

  ${({ position }) => {
    switch (position) {
      case 'top-left':
        return css`
          margin-right: ${spaceMb(-1)};

          ${screenMin('lg')} {
            margin-right: ${spaceDt(-2)};
          }
        `;
      case 'bottom-right':
        return css`
          margin-top: auto;
        `;
      default:
        return null;
    }
  }}
`;

const ContentWrapper = styled.div`
  display: flex;
  flex-direction: column;

  ${screenMax('lg')} {
    margin-left: ${spaceMb(1.5)};
    margin-right: ${spaceMb(1.5)};
  }

  ${screenMin('lg')} {
    padding-top: ${spaceDt(30)};
    margin: auto;
    width: ${toVW(720, 'desktop')};
  }
`;

const Content = styled.p`
  ${getTypography('body-1')}
  text-align: center;
  font-style: italic;
  position: relative;
  display: flex;

  ${screenMin('lg')} {
  }
`;

export { SectionContainer, ContentWrapper, Content, LinkExternalArticle, Hashtag };
