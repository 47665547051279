import React from 'react';
import PropTypes from 'prop-types';
import { TYPE_COMPONENT } from '@helpers/types';
import { ListDetails, ListVector, ListDescription } from './SectionList.styled';

const SectionList = ({ content, vectorName }) => (
  <ListDetails>
    <ListVector name={vectorName} />
    <ListDescription>{content}</ListDescription>
  </ListDetails>
);

export default SectionList;

SectionList.propTypes = {
  content: PropTypes.oneOfType([PropTypes.string, TYPE_COMPONENT]).isRequired,
  vectorName: PropTypes.string.isRequired,
};
