import React from 'react';
import { TextEffect } from '@fragments';

const dataManifesto = {
  sectionHeader: {
    name: 'Manifesto',
    headline: (
      <>
        We believe that every company
        <br /> can positively <TextEffect effect="highlight"> influence</TextEffect> <br /> its business, social and{' '}
        <br />
        climate environment.
      </>
    ),
  },
  sectionOptimize: {
    mainSection: {
      icon: 'icon-rune--d-darker',
      content: (
        <>
          Acting only to maximize revenue is not sustainable. Making money is important to run and scale a business. But
          it can’t be the only metric we optimize. We need to care as much about our planet and the social ecosystem.
          <br />
          <br />
          Ragnarson has two main purpose-based metrics.
        </>
      ),
    },
    bottomSection: [
      {
        icon: 'icon-rune--arrow',
        content: 'We reinvest around 40% of our profit in early-stage impactful startups.',
      },
      {
        icon: 'icon-rune--arrow',
        content: (
          <>
            Our software development services starting from 2022 will be provided mainly to companies having a positive
            impact.
          </>
        ),
      },
    ],
  },
  sectionInequalities: {
    mainSection: {
      icon: 'icon-rune--e-darker',
      content: (
        <>
          Inequalities in our socio-economic systems hinder our full potential. More equal access to opportunities and
          fairer distribution of wealth is what we need to aim for.
        </>
      ),
    },
    bottomSection: [
      {
        icon: 'icon-rune--arrow',
        content: 'Providing more transparency.',
      },
      {
        icon: 'icon-rune--arrow',
        content: 'Remote collaboration among companies.',
      },
    ],
    moreInfo: {
      content: (
        <>
          Transparency allows us to learn more from each other and eliminates the asymmetry of knowledge. Remote
          collaboration distributes capital geographically, enables companies from different regions to work together,
          and activates often previously untapped talent.
          <br />
          <br />
          We follow this principle by sharing our core KPIs and collaborating with our customers and employees remotely
          since 2006.
        </>
      ),
    },
  },
  sectionTransparency: {
    mainSection: {
      icon: 'icon-rune--f-darker',
      content: (
        <>
          By being transparent internally and giving more freedom, responsibility, and decision-making to the team, our
          employees are well-rounded and intrinsically motivated, which strengthens our business. It reinforces the
          amount of positive impact we have.
        </>
      ),
    },
    bottomSection: [
      {
        icon: 'icon-rune--arrow',
        content: (
          <>
            We do this by using the Teal Organisations principles: We are fully transparent and most decisions are
            decentralised.
          </>
        ),
      },
      {
        icon: 'icon-rune--arrow',
        content: 'The team is able to work fully remotely and decides what projects to engage in.',
      },
    ],
    team: {
      sign: 'Ragnarson Team',
    },
  },
  sectionQuote: {
    mainSection: {
      content: (
        <>
          The current technology and venture capital structure is broken. It rewards quantity over quality, consumption
          over creation, quick exits over sustainable growth, and shareholder profit over shared prosperity. It chases
          after “unicorn” companies bent on “disruption” rather than supporting businesses that repair, cultivate, and
          connect.
          <br />
        </>
      ),
      to: 'https://medium.com/zebras-unite/zebrasfix-c467e55f9d96',
      name: 'Zebras Fix What Unicorns Break',
    },
  },
};

export default dataManifesto;
